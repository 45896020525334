<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div class="mx-1" v-if="canVerb(resource, 'index')">
        <b-row>
          <b-col align="left">
            <b-button-group
                v-b-toggle:collapse-1
                class="my-2 filter-button-group"
                :title="getDefaultFilterMessage()"
            >
              <span class="when-open">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              <span class="when-closed">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              Filtra
            </b-button-group>
          </b-col>
          <b-col align="right">
            <b-button
                class="btn-create"
                type="button"
                variant="primary"
                size="sm"
                @click="onAdd"
                title="Crea"
                v-if="canVerb(resource, 'store')"
            >
              <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
              Crea
            </b-button
            >
          </b-col>
        </b-row>

        <b-collapse visible id="collapse-1">
          <div class="mt-2" id="collapse-1">
            <b-form @submit.prevent="onSearch(filterName)">
              <b-card class="filter">
                <b-row>
                  <div class="col-md-3">
                    <base-input
                        name="titolo"
                        v-model="filter.byAttribute.title"
                        type="text"
                        label="Descrizione"
                        placeholder="Inserisci una descrizione"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                        name="status_payment"
                        label="Stato"
                        :options="status_payment_options"
                        v-model="filter.byAttribute.status_payment"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-datepicker
                        name="effective_date"
                        label="Data"
                        v-model="filter.byCalendar.from"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-3">
                    <base-input
                        name="SURN"
                        v-model="filter.byRegistry.SURN"
                        type="text"
                        :label="getDictionary('attribute_SURN_value', 'registry')"
                        placeholder="Inserisci un cognome"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-input
                        name="NAME"
                        v-model="filter.byRegistry.NAME"
                        type="text"
                        :label="getDictionary('attribute_NAME_value', 'registry')"
                        placeholder="Inserisci un nome"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-input
                        name="creg"
                        v-model="filter.byRegistry.CREG"
                        type="text"
                        :label="getDictionary('attribute_CREG_value', 'registry')"
                        placeholder="Inserisci una partita IVA"
                    />
                  </div>
                  <div class="form-group col-md-3 align-self-end">
                    <b-button
                        type="submit"
                        variant="lisaweb"
                        size="sm"
                        v-b-toggle:collapse-1
                    >Cerca
                    </b-button
                    >
                    <b-button
                        class="btn-reset"
                        type="button"
                        variant="lisaweb"
                        size="sm"
                        @click="onClearFilter(filterName)"
                    >Reset
                    </b-button
                    >
                  </div>
                </b-row>
              </b-card>
            </b-form>
          </div>
        </b-collapse>

        <export-options
            :exportUrl="exportUrl"
            :repository="repository"
            :resource="resource"
            :tableRef="$refs[tableRef]"
            :filter="filter"
            :options="[{
            code: 'EXPOUNPA',
            label: null,
            formats: ['csv', 'pdf'],
          }]"
        ></export-options>

        <!-- @csv="onExport('csv')"
          :options="{ exportCSV: true }" -->
        <!-- <button-group-table></button-group-table> -->

        <table-unsolved
            @edit="onEdit"
            @destroy="onDestroy"
            :filterOn="{
            byRelations: ['byRegistry'],
          }"
            :fields="fields"
            :repository="repository"
            :resource="resource"
            :filterName="filterName"
            :ref="tableRef"
            :onlyActions="['edit', /* 'infomodal', */ 'destroy']"
            hasChecks
        ></table-unsolved>
        <b-row>
          <div class="form-group col-md-3">
            <b-button
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onSubmit()"
            >Incassa
            </b-button
            >
          </div>
        </b-row>
        <div>
          <select-treasury-modal
              :opts="treasury_options"
              @treasury="onTreasury"
          />
        </div>
      </div>
      <div v-else class="mt-3">
        <b-icon icon="info-circle" scale="1.00"></b-icon>
        Non hai i permessi
      </div>
    </div>
    <template #overlay>
      <div class="text-center">
        <base-icon name="loading" width="35" height="35"/>
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import TableUnsolved from "@/components/tables/UnsolvedPayments";
import ExportOptions from "@/components/ExportOptions";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import SelectTreasuryModal from "@/components/modals/selectTreasury";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import {toLocaleCurrency} from "@/utils/strings";
import {toLocaleDate} from "@/utils/dates";
import {toInfoData} from "@/utils/transforms";
import {today} from "@/utils/dates";
import {mapActions} from "vuex";

export default {
  mixins: [
    FormMixin,
    CustomFiltersMixin,
    ConfirmModalMixin,
    StorageGetterMixin,
  ],
  data() {
    return {
      exportUrl: null,
      isLoading: false,
      repository: "unsolved_payment",
      resource: "unsolved_payments",
      filterName: "filterUnsolvedPayments",
      filter: this.initFilter(),
      items: [],
      selectedUnsolvedPayments: [],
      treasury_options: [],
      treasury_id: null,
      treasuriesCode: [],
      tableRef: "UnsolvedPaymentsTableRef",
      fields: [
        {
          key: "check",
          label: "Sel.",
          thClass: "check",
          // class: "check align-middle",
          tdClass: "align-middle",
        },
        {
          key: "title",
          value: "title",
          label: this.getDictionary("title", "unsolved_payment"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "status_payment.text",
          value: "status_payment.text",
          label: this.getDictionary("status_payment", "unsolved_payment"),
        },
        {
          key: "contraente",
          value: (item) => toInfoData(item.registry, "registryfullname"),
          label: this.getDictionary("contractor"),
          sortable: false,
        },
        {
          key: "unsolved_at",
          label: this.getDictionary("unsolved_at", "unsolved_payment"),
          value: "unsolved_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "unsolved_at",
        },
        {
          key: "amount",
          value: "amount",
          label: this.getDictionary("amount", "unsolved_payment"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
      status_payment_options: [
        {value: "0", text: "Aperto"},
        {value: "1", text: "Usato"},
      ],
      form: {
        book_entry: {
          insurance_policy_number: null,
          book_date: null,
          effective_date: null,
          sheet_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: "N",
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {
            3: {},
          },
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        unsolved_payment: {
          status_payment: null,
          title: null,
          unsolved_at: null,
          amount: null,
          registry_id: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    TableUnsolved,
    // ButtonGroupTable,
    ExportOptions,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    SelectTreasuryModal,
  },
  created() {
    /* */
    // debugger;
    console.log("beforeCreate index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils"))
        // this.getResources("auth/councils");
        // resources.push({
        //   path: path,
        //   value: "councils",
        // });
      resources.push(
          this.$store
              .dispatch(`${path}councils`)
              .then(() => {
                console.info(`background data "${path}councils" fetched correctly`);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
          // .finally(() => {
          //   this.unloadMsg();
          //   this.isLoading = false;
          // })
      );
    if (!this.hasLoadedResource("provinces"))
        // this.getResources("auth/provinces");
        // resources.push({
        //   path: path,
        //   value: "provinces",
        // });
      resources.push(
          this.$store
              .dispatch(`${path}provinces`)
              .then(() => {
                console.info(
                    `background data "${path}provinces" fetched correctly`
                );
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
          // .finally(() => {
          //   this.unloadMsg();
          //   this.isLoading = false;
          // })
      );
    if (!this.hasLoadedResource("countries")) {
      resources.push(
          this.$store
              .dispatch(`${path}countries`)
              .then(() => {
                console.info(
                    `background data "${path}countries" fetched correctly`
                );
                // const defaultCountryId = this.getCountries().find(
                //   (country) => country.text == this.defaultCountryName
                // ).value;
                // this.opt_provinces = this.getProvinces()(defaultCountryId);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
          // .finally(() => {
          //   this.unloadMsg();
          //   this.isLoading = false;
          // })
      );
    }
    if (resources.length) {
      this.getResources(resources)
          .then(() => {
            console.log("All promises went through correctly");
          })
          .catch((error) => {
            console.log("One or more promises crashed... :|");
            this.onFailure(error);
          })
          .finally(() => {
            this.isLoading = false;
            // this.$emit("storageLoading", false);
          });
    } else {
      this.isLoading = false;
      // this.$emit("storageLoading", false);
    }
    /* */
  },
  beforeMount() {
    // this.treasury_options = this.getAllTreasuries().filter(
    //   (e) =>
    //     e.sector.is_abstract.value === "N" &&
    //     e.sector.is_system.value === "N"
    // );
    /* this.isLoading = true;
    const Repo = RepositoryFactory.get("treasury");
    Repo.index().then((response) => {
      const data = response.data.data;
      this.treasuriesCode = data.map((el) => {
        return {
          id: el.id,
          code: el.code,
        };
      });

      this.treasury_options = data
        .filter(
          (e) =>
            e.sector.is_abstract.value === "N" &&
            e.sector.is_system.value === "N" &&
            e.sector.is_agency.value === "N"
        )
        .map((e) => ({
          value: e.id,
          text: `${e.display} - ${e.title}`,
          code: `${e.code}`,
        }));
      this.isLoading = false;
    }); */
    const data = this.$store.state.auth.treasuries;
    this.treasuriesCode = data.map((e) => {
      return {
        id: e.id,
        code: e.code,
      };
    });

    this.treasury_options = data
        .filter(
            (e) =>
                e.sector.is_abstract.value === "N" &&
                e.sector.is_system.value === "N" &&
                e.sector.is_agency.value === "N"
        )
        .map((e) => ({
          value: e.id,
          text: `${e.display} - ${e.title}`,
          code: `${e.code}`,
        }));
    // .finally(() => {
    //   this.isLoading = false;
    // });
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_payment: "0",
          code: null,
          title: null,
        },
        byRegistry: {
          NAME: null,
          SURN: null,
          CREG: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "attributables") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `unsolved_payments.edit`,
        params: {id: `${id}`},
      });
    },
    onAdd() {
      this.$router.push({name: `unsolved_payments.create`});
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
          .then(() => {
            this.$refs[this.tableRef].fetch();
            this.$showSnackbar({
              preset: "success",
              text: "Insoluto Eliminato con successo",
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
    },
    onSearch(name) {
      // vogliono la data "secca"
      this.filter.byCalendar.to = this.filter.byCalendar.from;
      let criteria = this.filter;
      this.saveFilterByName({name, criteria});
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
          this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onTreasury(val) {
      // console.log("onTreasury", val);
      // se ho selezionato la cassa, salvo
      if (val) {
        this.treasury_id = val;
        this.showConfirm({
          yesCallback: () => {
            this.saveSelected();
          },
          // noCallback: () => {
          //   this.$bvModal.hide("createInsurancePaymentOptionsModal");
          // },
          noCallback: null,
          title: "Conferma",
          message: "Procedere con l'incasso?",
          yesLabel: "PROCEDI",
          yesVariant: "outline-lisaweb",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        this.$showSnackbar({
          preset: "info",
          text: "Selezionare una cassa",
        });
      }
    },
    onSubmit() {
      if (!this.isSomeChecked()) {
        this.$showSnackbar({
          preset: "info",
          text: "Selezionare almeno un insoluto da incassare",
        });
        return;
      }
      // TODO:
      /* modale con scelta "Quale cassa uso?" (solo casse reali: no sistema, no astratte) */

      this.$bvModal.show("selectTreasury");
    },
    saveSelected() {
      /*
        per ogni selectedRow:
          creo nuovo mov contabile (identico all'acconto) type: various accounting 3 (ACCONTO)
        quando ha finito, aggiorno lo stato delle selectedRow da Aperto a Chiuso */
      // console.log("saveSelected");
      let promises = [];
      this.selectedUnsolvedPayments = [];
      for (let id of this.$refs[this.tableRef].selectedRows) {
        let unsolved_payment = this.$refs[this.tableRef].items.find(
            (e) => e.id === id
        );
        this.selectedUnsolvedPayments.push(unsolved_payment); // servirà per aggiornare status_payment
        promises.push(this.storeBookEntry(unsolved_payment, "book_entry"));
      }
      this.isLoading = true;
      Promise.all(promises)
          .then(() => {
            let promises = [];
            for (let up of this.selectedUnsolvedPayments) {
              // su dovrebbe chiamare la fetchEditForm e setMandatoryFields per ciascun id, ma...
              // siccome ho già i dati della index, e sono 5 campi in croce, faccio prima a PUTtarli tutti
              const {title, unsolved_at, amount, registry_id} = up;
              let payload = {
                status_payment: "1",
                title,
                unsolved_at,
                amount,
                registry_id,
              };
              promises.push(this.update(this.repository, up.id, payload));
            }
            Promise.all(promises)
                .then(() => {
                  this.$showSnackbar({
                    preset: "info",
                    text: "Incasso eseguito con successo",
                  });
                  this.isLoading = false;
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
    },
    // setContractor(unsolved_payment, repository) {
    //   if (unsolved_payment.registry_id) {
    //     this.form[repository].registry = { [unsolved_payment.registry_id]: {} };
    //   }
    // },
    storeBookEntry(unsolved_payment, repository) {
      // if (this.form[this.rep].gross != this.entryDetailsCreateTotal) {
      //   this.$showSnackbar({
      //     preset: "error",
      //     text: `Il lordo deve essere uguale al totale delle forme di pagamento`,
      //   });
      //   return;
      // }

      // prepare form data
      this.form[
          repository
          ].title = `Incasso insoluto ${unsolved_payment.title}`;
      this.form[repository].gross = unsolved_payment.amount;
      this.form[repository].book_date = today();
      this.form[repository].effective_date = this.form[repository].book_date;
      // sheet_date automatically equals book_date
      this.form[repository].sheet_date = this.form[repository].book_date;

      // this.setContractor(unsolved_payment, repository);
      if (unsolved_payment.registry_id) {
        this.form[repository].registry = {[unsolved_payment.registry_id]: {}};
      }

      return this.store(repository)
          .then((response) => {
            // console.debug(response);
            let data = response.data.data;
            const entryDetailsPromises = this.storeEntryDetails(
                data,
                unsolved_payment,
                "entry_detail"
            );
            Promise.all(entryDetailsPromises)
                .then(() => {
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
    },
    storeEntryDetails(book_entry_data, unsolved_payment, repository) {
      let storeEntryDetails = [];
      this.form[repository].book_entry_id = book_entry_data.id;
      this.form[repository].detail_date = book_entry_data.book_date;
      // Object.values(this.form.book_entries.entry_details)
      //   .filter((treasury) => treasury.type !== null && treasury.type !== null)
      //   .forEach((filteredTreasury) => {
      //     this.form[repository].gross = filteredTreasury.amount;
      //     this.form[repository].treasury_id = filteredTreasury.type;
      //     let trs = this.store(repository);
      //     storeEntryDetails.push(trs);
      //   });
      // aggiungo l'unica cassa selezionata:
      this.form[repository].gross = unsolved_payment.amount;
      this.form[repository].treasury_id = this.treasury_id;
      let trs = this.store(repository);
      storeEntryDetails.push(trs);

      // aggiungo un acconto
      this.form[repository].gross = unsolved_payment.amount;
      this.form[repository].treasury_id = this.treasuriesCode.find(
          (el) => el.code === "AC"
      ).id;
      storeEntryDetails.push(this.store(repository));

      return storeEntryDetails;
    },
    isSomeChecked() {
      return !!this.$refs[this.tableRef].selectedRows.length;
    },
    // ...mapGetters("auth", {
    //   getAllTreasuries: "allTreasuries",
    // }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {removePaginationByName: "removeByName"}),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({name, criteria});
    },
  },
};
</script>
